import React from 'react'
import { RichText } from 'prismic-reactjs';

const Text  = (data) => {

  if (!data.slice.primary.content) return null

  return(
    <section className="container text-pp rte">
      <div className="container__inner text-pp__inner">
        <RichText render={data.slice.primary.content} />
      </div>
    </section>
  )
}

export default Text;