import React from 'react'
import Text from './Sections/Text'
import Image from './Sections/Image'
import TwoColBlock from './Sections/TwoColBlock'
import TwoImages from './Sections/TwoImages'
import Cta from './Sections/Cta'

const SliceZone = ({ sliceZone }) => {

  const sliceComponents = {
    two_column_block: TwoColBlock,
    two_images_block: TwoImages,
    image_block: Image,
    text_block: Text,
    cta_block: Cta
  }

  const sliceZoneContent = sliceZone.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    }
    return null
  })

  return <main className="container">{sliceZoneContent}</main>

}

export default SliceZone