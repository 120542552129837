import React from 'react'
import Image from '../Image'

const TwoColBlock  = (data) => {

  if (!data.slice.primary.content && !data.slice.primary.image) return null

  const sectionData = data.slice.primary
  let classes = 'container two-col-block '

  if (!sectionData.align_image || sectionData.align_image === 'Left'){
    classes += 'two-col-block--left'
  } else {
    classes += 'two-col-block--right'
  }

  return(
    <section className={classes}>
    <div className="container__inner two-col-block__inner">
      <Image src={sectionData.image.url} sizes="(min-width: 45em) 50vw, 100vw" classes="two-col-block__image objFit"></Image>
      <div className="two-col-block__content">
        { sectionData.title[0] ? <h3 className="two-col-block__content-title">{ sectionData.title[0].text }</h3> : null }
        <div className="two-col-block__content-content">
          {sectionData.content.map((item, i) => {
            return(
            <p key={i}>{item.text}</p>
            )
          })}
        </div>
      </div>
    </div>
  </section>
  )
}

export default TwoColBlock;