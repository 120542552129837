import React from 'react'

const TwoImages  = () => {
  return(
    <div></div>
    // {% if twoImages is not empty %}
    //   <section className="container two-images">
    //     <div className="container__inner two-images__inner">
    //       <div className="two-images__col">
    //         {{ include('partial/image.twig', {
    //           'image': twoImages.image_1,
    //           'sizes': '50vw',
    //           'class': 'two-images__image'
    //         }) }}
    //         {% if twoImages.image_1_caption %}
    //           <div className="two-images__caption">{{ twoImages.image_1_caption }}</div>
    //         {% endif %}
    //       </div>
    //       <div className="two-images__col">
    //         {{ include('partial/image.twig', {
    //           'image': twoImages.image_2,
    //           'sizes': '50vw',
    //           'class': 'two-images__image'
    //         }) }}
    //         {% if twoImages.image_1_caption %}
    //           <div className="two-images__caption">{{ twoImages.image_1_caption }}</div>
    //         {% endif %}
    //       </div>
    //     </div>
    //   </section>
    // {% endif %}
  )
}

export default TwoImages;