import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout/Layout'
import { Helmet } from 'react-helmet'
import Seo from "../components/seo"
import SliceZone from '../components/SliceZone'
import SocialSharing from '../components/SocialSharing'
import Image from '../components/Image'
import ArrowLeftSvg from '../assets/images/icons/arrow-left.svg'

export const query = graphql`
  query BlogPostQuery($uid: String) {
    allPrismicBlogPost(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          uid
          data {
            image {
              url
            }
            title {
              text
            }
            summary {
              text
            }
            logo_colour
            date
            body {
              ... on PrismicBlogPostDataBody {
                slice_type
                primary {
                  content {
                    text
                    type
                    spans {
                      start
                      type
                      end
                    }
                  }
                  image1 {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const BlogPost = ({ data }) => {
  if (!data) return null
  const page = data.allPrismicBlogPost.edges[0].node

  return (
    <Layout>
      <Seo title={ page.data.title[0].text } description={ page.data.summary[0].text } />
      <Helmet>
        <htmlAttributes className={`page--blog-post ${page.data.logo_colour === 'White' && 'header-logo-dark'}`}></htmlAttributes>
      </Helmet>
      <article itemScope itemType="http://schema.org/Article">

        <section className="container article-banner">
          <div className="container__inner article-banner__inner">
            <Image src={page.data.image.url} sizes="50vw" classes="article-banner__image objFit"></Image>

            <div className="article-banner__content">
              <Link to="/blog" className="article-banner__content-back"><ArrowLeftSvg /> Back to blog</Link>
              <time className="article-banner__content-date" itemProp="datePublished dateCreated">{ page.data.date }</time>
              <h1 className="article-banner__content-title" itemProp="headline name">{ page.data.title[0].text }</h1>
              <p className="article-banner__content-summary" itemProp="description">{ page.data.summary[0].text }</p>
            </div>
          </div>
        </section>
        <section className="container container--content article-body" itemProp="articleBody">
          <div className="container__inner article-body__inner rte">
            <SliceZone sliceZone={page.data.body} />
            <div className="article-body__share">
              <SocialSharing title={page.data.title[0].text} link={page.uid} image={page.data.image.url} />
            </div>
          </div>
        </section>
      </article>
    </Layout>
  )

}

export default BlogPost