import React from 'react'
import PinterestSvg from '../assets/images/icons/pinterest.svg'
import FacebookSvg from '../assets/images/icons/facebook.svg'
import TwitterSvg from '../assets/images/icons/twitter.svg'
import EmailSvg from '../assets/images/icons/email.svg'

const SocialSharing  = ({title, link, image}) => {

  const url = typeof window !== 'undefined' ? window.location.href : '';

  const pinterestLink = '//pinterest.com/pin/create/button/?url='+ url+ '&amp;media=' + image + '&amp;description=' + title
  const facebookLink = '//www.facebook.com/sharer.php?u=' + url
  const twitterLink = '//twitter.com/share?text=' + title + '&amp;url=' + url
  const emailLink = 'mailto:?to=&subject=' + title + '&body=' + url

  return(
    
    <div className="section social-sharing">
      <h5 className="social-sharing__title">Share</h5>

    
        <a className="social-sharing__link" target="_blank" rel="noopener noreferrer" href={pinterestLink}>
          <PinterestSvg />
          <span className="screenreader-text">Share on Pinterest</span>
        </a>
      
        <a className="social-sharing__link" target="_blank" rel="noopener noreferrer" href={facebookLink}>
          <FacebookSvg />
          <span className="screenreader-text">Share on Facebook</span>
        </a>

        <a className="social-sharing__link" target="_blank" rel="noopener noreferrer" href={twitterLink}>    
          <TwitterSvg />
          <span className="screenreader-text">Share on Twitter</span>
        </a>

        <a className="social-sharing__link" href={emailLink} title="Share via email">
          <EmailSvg />
          <span className="screenreader-text">Share via Email</span>
        </a> 
    </div>

  )
}

export default SocialSharing;