import React from 'react'

const CTA  = (data) => {

  if (!data) return null

  const sectionData = data.slice.primary

  return(
    <section className="container cta ">
      <div className="container__inner cta__inner">
        <h3 className="cta__title">{ sectionData.title }</h3>
        <div className="cta__content">
          {sectionData.content.map((item, i) => {
            return(
            <p key={i}>{item.text}</p>
            )
          })}
        </div>
        <a href={sectionData.link.url} className="button cta__button">{sectionData.link_text[0].text}</a>
      </div>
    </section>
  )
}

export default CTA;