import React from 'react'
import ImageComponent from '../Image'

const Image  = (data) => {

  if (!data.slice.primary.image1) return null

  return(
      <section className="container image-pp">
        <div className="container__inner image-pp__inner">
          <ImageComponent src={data.slice.primary.image1.url} classes="image-pp__image"></ImageComponent>
          { data.slice.primary.caption ? <div className="image-pp__caption">{ data.slice.primary.caption[0].text }</div> : null }
        </div>
      </section>
  )
}

export default Image;